*,
*::after,
*::before {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

body {
  background-color: #f0f2f5 !important;
}

.center-of-page {
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.center-of-page-without-nav {
  height: calc(100vh - 200px);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.alert-box {
  margin: 5px 3px;
}

.cap {
  text-transform: capitalize;
}

.title {
  font-size: 15px;
  font-weight: 500;
  text-transform: uppercase;
}

.action {
  color: #40a9ff;
  cursor: pointer;
}

.blank {
  color: #ff8787;
}

.active::before {
  content: '';
  height: 10px;
  width: 10px;
  background-color: #37b24d;
  border-radius: 50%;
  display: inline-block;
  margin-right: 5px;
}

.inactive::before {
  content: '';
  height: 10px;
  width: 10px;
  background-color: #f03e3e;
  border-radius: 50%;
  display: inline-block;
  margin-right: 5px;
}
